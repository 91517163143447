/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import "/build/88f48d7e-3b3f-44a5-b4a2-16bcfeda3956/vendor/bundle/ruby/3.2.0/gems/cocoon-1.2.15/app/assets/javascripts/cocoon";
import "./stylesheets.scss"

global.$ = jQuery;

Rails.start();
Turbolinks.start();